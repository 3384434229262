export default ({type,name,label,placeholder, fieldRef, hasError}) => {


    const classes = 'form-control pl-3 laptopmd:w-[30rem] w-full py-1.5 text-blue-900 rounded border border-solid border-blue-900 focus:border-pink-600 focus:outline-none';

   return (
        <div className="form-group mb-6 mt-3">
                <label className="block text-blue-900 text-sm font-bold mb-2">{label}</label>

                {type === 'textarea' ? (
                       <textarea className={classes} name={name}  rows='3' placeholder={placeholder} {...fieldRef} />
                    ) :
                    (
                        <input className={classes} type={type} name={name} placeholder={placeholder} {...fieldRef} />
                    )}

                    {hasError && <p className="text-red-500 text-xs italic">{`${label} is required`}</p>}
        </div>
    
)};