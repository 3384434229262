import css from  './Testimonial.module.css';
import {Swiper, SwiperSlide} from 'swiper/react';
import { Autoplay, Pagination, Navigation } from "swiper";
import BGImage from '../../assets/bg.webp';


import {data} from '../../components/testimonial/data';


import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


const Testimonial =  () => {

  return (
    <div className='h-[41rem]'>
      <span className='flex justify-center xs:text-[1.15rem] xs:pt-[1.2rem] xxxs:text-[1.15rem] xxxs:pt-[1.2rem] xxs:text-[1.15rem] xxs:pt-[1.2rem] samTNO:text-[1.15rem] samTNO:pt-[1.2rem] samTNT:text-[1.15rem] samTNT:pt-[1.2rem] iphoneTwelve:text-[1.15rem] iphoneTwelve:pt-[1.2rem] xss:text-[1.15rem] xss:pt-[1.2rem] iphoneFifteen:text-[1.15rem] iphoneFifteen:pt-[1.2rem] samsungBig:text-[1.15rem] samsungBig:pt-[1.2rem] bg-opacity-90 sm:text-[1.3rem] md:text-[1.3rem] iphone:text-[1.3rem] md:pb-[0.85rem] iphone:pb-[0.8rem] xxs:pb-[0.75rem] xs:pb-[0.8rem] sm:pb-[.8rem] xxxs:pb-[1rem] iphoneTwelve:pb-[1rem] samsungBig:pb-[1rem] samTNO:pb-[1rem] xss:pb-[1rem] iphoneFifteen:pb-[1rem] p-[.9rem] bg-blue-900 text-[1.35rem] text-white text-center tracking-wider'>Meet Our <strong> <span className='ml-[.4rem] tracking-wider'>Talented Tutors</span> </strong></span>
      <div className='flex relative'>
          <img className='absolute z-[-999] lg:w-full  h-[41rem]' src={BGImage} alt='bg' />
        <div className='absolute w-full h-[42rem] bg-gradient-app-bg opacity-[.63]'></div>
     </div>
    <div className='flex h-[45rem]'>


      
   <div className='h-[41rem] xs:pb-24 xs:h-[40rem] xxxs:pb-24 xxxs:h-[40rem] xxs:pb-24 xxs:h-[40rem] samTNO:pb-24 samTNO:h-[40rem] samTNT:pb-24 samTNT:h-[40rem] iphoneTwelve:pb-24 iphoneTwelve:h-[40rem] xss:pb-24 xss:h-[40rem] iphoneFifteen:pb-24 iphoneFifteen:h-[40rem] laptopmd:h-[45rem] samsungBig:pb-24 samsungBig:h-[40rem] md:w-[100%] xxs:w-[100%] xs:w-[100%] sm:w-[100%] iphone:w-[100%] w-[100%] flex justify-center items-center relative'>
            <Swiper 
            centeredSlides={true}
            autoplay={{
              delay: 3500,
              disableOnInteraction: false,
              pauseOnMouseEnter:true
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className={css.mySwiper}
            loopFillGroupWithBlank={true}
            spaceBetween={700} 
            slidesPerGroup={1}
       
            loop={true}>
                {data.map((slide, i) => (
                    <SwiperSlide key={i}> 
                        <div className='flex relative xs:h-[29rem] md:w-[50rem] sm:w-[42rem] iphone:w-[42rem] xs:w-[28rem] xxxs:h-[29rem] xxxs:w-[22.6rem] xxs:h-[29rem] xxs:w-[23.6rem] samTNO:h-[29rem] samTNO:w-[25rem] samTNT:h-[29rem] samTNT:w-[25rem] iphoneTwelve:h-[29rem] iphoneTwelve:w-[27rem] xss:h-[29rem] xss:w-[27rem] iphoneFifteen:h-[29rem] samsungBig:h-[29rem] iphoneFifteen:w-[28rem] samsungBig:w-[28rem] xxl:w-[92rem] lg:w-[66rem] mmmIphone:w-[33rem] IphoneProMax:w-[34.7rem] align-middle justify-center w-[45rem] xxl:h-[32rem] iphone:h-[27rem] sm:h-[28rem] h-[34rem]'>
                            <section class="opacity-[.63] shadow-sm xs:h-[24rem] xs:w-[21rem] xxxs:h-[24rem] xxxs:w-[20.5rem] xxs:h-[24rem] xxs:w-[21rem] samTNO:h-[24rem] samTNO:w-[21rem] samTNT:h-[24rem] samTNT:w-[22rem] iphoneTwelve:h-[24rem] iphoneTwelve:w-[24rem] xss:h-[24rem] xss:w-[24rem] iphoneFifteen:h-[24rem] iphoneFifteen:w-[24rem] samsungBig:h-[24rem] samsungBig:w-[24rem] xs:mr-4 iphoneTwelve:mr-4 samsungBig:mr-4 xss:mr-4 iphoneFifteen:mr-4 xxl:w-[55rem] lg:w-[47rem] md:w-[42rem] iphone:w-[31rem] sm:w-[33rem] rounded-2xl xs:mt-20 xxs:mt-20 samTNO:mt-20 samTNT:mt-20 xss:mt-20 iphoneTwelve:mt-20 iphoneFifteen:mt-20 samsungBig:mt-20 sm:mt-24 mt-28 bg-slider-color dark:bg-gray-900 flex justify-center items-center align-middle">
                                <div className="mx-auto text-center lg:px-6">
                                  <figure>
         
                                      <img  className=" absolute lg:top-[-.03rem] lg:left-[22%] sm:top-[-0.03rem] sm:left-[22%] sm:w-[9rem] sm:h-[9rem] xs:top-[-0.03rem] iphone:left-[22%] iphone:top-[-.03rem] xs:left-[22%] xxxs:top-[-0.03rem] xxxs:left-[22%] xxs:top-[-0.03rem] xxs:left-[22%] samTNO:top-[-0.03rem] samTNO:left-[22%] samTNT:top-[-0.03rem] samTNT:left-[22%] iphoneTwelve:top-[-0.03rem] iphoneTwelve:left-[22%] xss:top-[-.03rem] xss:left-[22%] iphoneFifteen:top-[-0.03rem] iphoneFifteen:left-[22%] samsungBig:top-[-0.03rem] samsungBig:left-[22%] rounded-[100%] xs:w-[8rem] xs:h-[8rem] xxxs:w-[8rem] xxxs:h-[8rem] xxs:w-[9rem] xxs:h-[9rem] samTNT:w-[9rem] samTNT:h-[9rem] samsungBig:w-[9rem] iphoneTwelve:h-[9rem] samTNO:w-[9rem] samTNO:h-[9rem] iphoneTwelve:w-[9rem] samsungBig:h-[9rem] top-[1.5rem] xxl:left-[45%] left-[37%]  w-[12rem] h-[12.8rem]" src={slide.img} alt='' />
                                      <blockquote className='w-[36rem] xs:w-[96%] xs:pl-5 xs:pt-[1rem] xxxs:w-[96%] xxxs:pl-5 xxxs:pt-[1rem] xxs:w-[96%] xxs:pt-[1.8rem] samTNO:w-[96%] samTNO:pt-[1.8rem] samTNT:w-[96%] samTNT:pt-[1.8rem] iphoneTwelve:w-[96%] iphoneTwelve:pt-[1.8rem] xss:w-[96%] xss:pt-[1.8rem] iphoneFifteen:w-[96%] iphoneFifteen:pt-[1.8rem] samsungBig:w-[96%]  samsungBig:pt-[1.8rem] xxl:w-[44rem] xxl:pl-0 xxl:pr-10 xxs:pl-10 md:pl-14 md:w-[40rem] iphone:w-[29rem] sm:w-[30rem] pl-14 pr-0 pt-[5rem]'>
                                          <p className="text-lg xs:text-[.9rem] xs:mt-4 xs:font-[500] xs:leading-7 xxxs:text-[.9rem] xxxs:mt-4 xxxs:font-[500] xxxs:leading-7 xxs:text-[1rem] xxs:mt-4 xxs:font-[500] xxs:leading-7 samTNO:text-[1rem] samTNO:mt-4 samTNO:font-[500] samTNO:leading-7 samTNT:text-[1rem] samTNT:mt-4 samTNT:font-[500] samTNT:leading-7 iphoneTwelve:text-[1rem] iphoneTwelve:mt-4 iphoneTwelve:font-[500] iphoneTwelve:leading-7 xss:text-[1rem] xss:mt-4 xss:font-[500] xss:leading-7 iphoneFifteen:text-[1rem] iphoneFifteen:mt-4 iphoneFifteen:font-[500] iphoneFifteen:leading-7 samsungBig:text-[1.1rem] samsungBig:mt-4 samsungBig:font-[500] samsungBig:leading-7 xxl:text-[1.3rem] md:text-[1.1rem] font-normal iphone:leading-6 leading-7 xxl:leading-7 text-blue-950 dark:text-white">{slide.text}</p>
                                      </blockquote>
                                      <figcaption className="flex items-center justify-center mt-10 space-x-3">
                                        
                                          <div class="flex items-center xs:mb-4 xxs:mb-4 samTNO:mb-4 samTNT:mb-4 iphoneTwelve:mb-4 xxxs:mb-20 xss:mb-4 iphoneFifteen:mb-4 samsungBig:mb-4 divide-x-2 divide-blue-950 dark:divide-gray-700">
                                              <div class="pr-[.4rem] xs:text-[1rem] xxxs:text-[1rem] xxs:text-[1rem] samTNO:text-[1rem] samTNT:text-[1.15rem] xxl:text-[1.15rem] iphone:text-[1rem] font-medium text-blue-950 samsungBig:text-[1.2rem] iphoneTwelve:text-[1.2rem] xss:text-[1.2rem] iphoneFifteen:text-[1.2rem] dark:text-white">{slide.name}</div>
                                              <div class="pl-[.4rem] xs:text-[1rem] xxxs:text-[1rem] xxs:text-[1rem] samTNO:text-[1rem] samTNT:text-[1.15rem] xxl:text-[1.15rem]  iphone:text-[1rem] iphoneTwelve:text-[1rem] iphoneTwelve:font-medium xss:text-[1rem] xss:font-medium iphoneFifteen:text-[1rem] iphoneFifteen:font-medium samsungBig:text-[1rem] samsungBig:font-medium text-sm font-light text-blue-800 dark:text-blue-400">{slide.job}</div>
                                          </div>
                                      </figcaption>
                                </figure>
                              </div>
                            </section>

                        </div>

                    </SwiperSlide>
                ))}

           
        </Swiper>
   </div>
   </div>
   </div>
  
  );
};

export default Testimonial;