import React from 'react';
import {Segment} from 'semantic-ui-react';
import {WhatsappIcon} from 'react-share';
import {motion} from 'framer-motion';
import ReactWhatsapp from 'react-whatsapp';

const Whatsapp = () => {

  const transition = {duration: 2, type: 'spring'}  

  return (
    <motion.div 
     initial={{right:'-72%'}}
     whileHover={{right: '-58%'}}
     transition={transition}
    
    className='absolute laptopmd:top-[88%] mlg:top-[37%] md:top-0  samsungBig:top-[-1rem]  sm:top-0 iphone:top-0 miniIphone:top-0 mmIphone:top-0 mmmIphone:top-0 xs:top-0 xss:top-0 xxs:top-0 lg:top-[37%] top-[78%] h-[3.2rem] w-[12rem] flex align-middle bg-green-500 rounded-2xl'>
        <Segment className='flex text-white align-middle'>
                <ReactWhatsapp number='+971503406781' message='Hello AIM TEACH, '>
                    <WhatsappIcon size={45}  borderRadius={20}></WhatsappIcon>
                    
                </ReactWhatsapp>
                <span className='pl-3 pt-[0.55rem]'>Contact Us</span>
        </Segment>
    </motion.div>
  )
}

export default Whatsapp;