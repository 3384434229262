import React from 'react';
import numberOne from '../../assets/number-1.png';
import numberTwo from '../../assets/number-2.png';
import numberThree from '../../assets/number-3.png';

const Planning = () => {
  return (
    <div className='flex flex-col justify-center items-center relative lg:h-[70rem] sm:h-[87rem] xs:h-[87rem] xxxs:h-[80rem] xxs:h-[80rem] samTNO:h-[80rem] samTNT:h-[80rem] iphoneTwelve:h-[85rem] xss:h-[80rem] iphoneFifteen:h-[80rem] samsungBig:h-[80rem] laptopmd:h-[60rem] sm:mt-[1.5rem] xs:mt-[1.5rem] xxxs:mt-[2rem] samTNO:mt-[2rem] samTNT:mt-[2rem] samsungBig:mt-[2rem] iphoneTwelve:mt-[1.5rem] xss:mt-[1.5rem] iphoneFifteen:mt-[1.5rem] mt-0  bg-white'>
      <h1 className='absolute top-12 sm:top-[6rem] sm:pt-[4rem] xs:top-[6rem] xs:pt-[4rem] xs:pl-[1rem] xs:w-[90%] xs:text-2xl xxxs:top-[-6rem] xxxs:text-2xl xxxs:pt-[4rem] xxxs:pl-[1.8rem] xxs:top-[-6rem] text-3xl  xxs:pt-[4rem] xxs:pl-[1.8rem] samTNO:w-[90%] samTNO:text-2xl samTNO:pt-[4rem] samTNO:pl-[1rem] samTNT:w-[90%] samTNT:text-2xl samTNT:pt-[4rem] samTNT:pl-[1rem] iphoneTwelve:w-[90%] iphoneTwelve:text-2xl iphoneTwelve:pt-[4rem] iphoneTwelve:pl-[1rem] xxxs:text-[1.3rem] xxxs:w-[96%] xxs:text-[1.35rem] xxs:w-[96%] xss:w-[90%] xss:text-2xl xss:pt-[4rem] xss:pl-[1rem] iphoneFifteen:w-[90%] iphoneFifteen:text-[1.4rem] iphoneFifteen:pt-[1rem] iphoneFifteen:pl-[1rem]  samsungBig:pl-[1rem] laptopmd:text-[1.8rem] lg:text-2xl md:text-xl lg:text-[2rem] md:text-[1.65rem] laptopmd:mb-[4rem] xs:mt-[-1rem] sm:mt-[-1rem] md:mt-[.4rem] mt-[8rem] text-blue-900'><strong>Personalized learning plans</strong> based on your needs
      <hr className='h-[2px] ml-0 my-4 lg:w-[28.4rem] md:ml-0 md:w-[23.5rem] iphone:ml-[8rem] xs:ml-[4rem] xxxs:ml-[4rem] xxxs:w-[10rem] xxs:ml-[4rem] samTNO:ml-[4rem] samTNT:ml-[4rem] iphoneTwelve:ml-[4rem] xss:ml-[4rem] iphoneFifteen:ml-[4rem] samsungBig:ml-[4rem] bg-red-900 w-[26.65rem] mmmIphone:w-[14.3rem]  sm:w-[16rem] iphone:w-[16rem] xs:w-[12.8rem] laptopmd:w-[25.5rem] xxs:w-[11.1rem] border-0 dark:bg-red-700'/>
      </h1>

    <div className='grid xs:mt-0 xs:h-[50rem] laptopmd:h-[47rem] xs:gap-14 xxxs:mt-0 xxxs:h-[50rem] xxxs:gap-14 xxs:mt-[1rem] xxs:h-[50rem] xxs:gap-14 samTNO:mt-[3rem] samTNO:h-[55rem] samTNO:gap-14 samTNT:mt-[3rem] samTNT:h-[55rem] samTNT:gap-14 iphoneTwelve:mt-[3rem] iphoneTwelve:h-[55rem] iphoneTwelve:gap-14 xss:mt-[3rem] xss:h-[55rem] xss:gap-14 iphoneFifteen:mt-[3rem] iphoneFifteen:h-[55rem] iphoneFifteen:gap-12 samsungBig:mt-[3rem] samsungBig:h-[55rem] samsungBig:gap-10 laptop:grid-cols-3 gap-6 h-[30rem] p-10 mt-[8rem] xs:grid-cols-1'>
      
        <div className='flex justify-center items-center'>
       <div
        className="block rounded-lg bg-white text-center h-[17rem] shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
        <div
          className="flex items-center justify-center border-b-2 border-neutral-100 px-6 py-3 dark:border-neutral-600 dark:text-neutral-50">
            <img src={numberOne} alt='number one' className='h-12 xxxs:h-10 xs:h-10'/>
        </div>
        <div className="p-6">
          <h5
            className="mb-7 lg:mb-2 text-lg font-medium leading-tight text-blue-900 dark:text-neutral-50">
               At AIM TEACH, we start by trying to understand your needs, requirements, and goals.
          </h5>
         
        </div>
      
      </div>
      </div>

      <div className='flex justify-center items-center'>
       <div
        className="block rounded-lg bg-white text-center  h-[17rem] shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
        <div
          className="flex justify-center items-center border-b-2 border-neutral-100 px-6 py-3 dark:border-neutral-600 dark:text-neutral-50">
          <img src={numberTwo} alt='number one' className='h-12 xxxs:h-10 xs:h-10'/>
        </div>
        <div className="p-6">
          <h5
            className="mb-7 lg:mb-2 text-lg font-medium leading-tight text-blue-900 dark:text-neutral-50">
             Based on our analysis, we provide you with a list of recommended tutors and practitioners. After selection, a free trial session will be scheduled between the two parties.
          </h5>
   
        </div>
      
      </div>
      </div>

      <div className='flex justify-center items-center'>
       <div
        className="block rounded-lg bg-white text-center  h-[17rem] shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
        <div
          className="flex justify-center items-center border-b-2 border-neutral-100 px-6 py-3 dark:border-neutral-600 dark:text-neutral-50">
               <img src={numberThree} alt='number one' className='h-12 xxxs:h-10 xs:h-10'/>
        </div>
        <div className="p-6">
          <h5
            className="mb-7 lg:mb-2 text-lg font-medium leading-tight text-blue-900 dark:text-neutral-50">
             Once we recieve satisfactory feedback, a learning plan will be developed to cater your needs.
          </h5>
        
        
        </div>
       
      </div>
      </div>
    </div>
</div>
  )
}

export default Planning;