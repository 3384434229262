import computerIcon from '../assets/COMPUTER-icon.svg';
import javaIcon from '../assets/java.png';
import pythonIcon from '../assets/pythonIcon.png';
import flutterIcon from '../assets/flutter.png';
import aiIcon from '../assets/ai.png';
import softwareIcon from '../assets/software.png';
import shoppingIcon from '../assets/shopping.png';
import satIcon from '../assets/SAT.png';
import languagesIcon from '../assets/languages-icon.png';
import accountingIcon from '../assets/accounting.png';

export const SliderProducts = [{
    name: 'UI/UX ',
    detail: 'Web Design',
    price: '14 Courses',
    img:computerIcon
},
{
    name: 'Java & JavaFX',
    detail: 'Comprehensive Java.',
    price: '5 Courses',
    img: javaIcon
},
{
    name: 'Python Django',
    detail: 'Python Programming & Django.',
    price: '5 Courses',
    img: pythonIcon
},
{
    name: 'Flutter',
    detail: 'Mobile Application Development.',
    price: '3 Courses',
    img: flutterIcon
},
{
    name: 'AI & Robotics',
    detail: 'Machine learning, Deep Learning & Neural Networks.',
    price: '9 Courses',
    img: aiIcon
},
{
    name: 'Software Engineering',
    detail: 'Development, Testing & Maintenance.',
    price: '12 Courses',
    img: softwareIcon
},
{
    name: 'Digital Marketing',
    detail: 'Digital Marketing Techniques.',
    price: '3 Courses',
    img: shoppingIcon
},
{
    name: 'SAT Preparation',
    detail: 'Achieve Your Target Score.',
    price: '2 Courses',
    img: satIcon
},
{
    name: 'Languages & Literature',
    detail: 'Arabic, English, French & Spanish.',
    img: languagesIcon
},
{
    name: 'Finance & Accounting',
    detail: 'Develop Knowledge of Financial Principles.',
    price: '7 Courses',
    img: accountingIcon
},


]
