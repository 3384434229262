import React from 'react';
import './Slider.css';
import {Swiper, SwiperSlide} from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import { useNavigate } from 'react-router-dom';
import {GoArrowSwitch} from 'react-icons/go';


// Import Swiper Style
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {SliderProducts} from '../../data/products';

const Slider = () => {
  const navigate = useNavigate();
  return (

    <div className='s-container'>
        
        <Swiper 
          breakpoints={{
                // when window width is >= 425
                425: {
                width: 380,
                slidesPerView: 1,
                },
                // when window width is >= 768px
                768: {
                width: 768,
                slidesPerView: 2,
                
                },
            }}
            modules={[Pagination, Navigation]}
            className='mySwiper'
            navigation={true}
            loopFillGroupWithBlank={true}
            // slidesPerView={3}
            spaceBetween={40} 
            slidesPerGroup={1}
            loop={true}>
                {SliderProducts.map((slide, i) => (
                    <SwiperSlide key={i}>
                        <div className='left-s'>
                            <div className='name'>
                                <span>{slide.name}</span>
                                <span>{slide.detail}</span>
                            </div>

                            <span>{slide.price}</span>
                            <button onClick={() => navigate('/contact-form')} className='hover:bg-colorSpecialBlue text-[1remrem] hover:duration-300 hover:text-white hover:transition-all hover:ease-in-out'>GET A FREE SESSION</button>
                        </div>

                        <img src={slide.img} alt='product' className='img-p'/>
                    </SwiperSlide>
                ))}

           
        </Swiper>
        <GoArrowSwitch size={33} className='arrow-right'/>
    </div>
    
  )
}

export default Slider;