import React from 'react';
import css from './Home.module.css';
import Hero from '../../components/hero/Hero';
import Slider from '../../components/slider/Slider';
import Testimonial from '../../components/testimonial/Testimonial';
import Virtual from '../../components/virtual/Virtual';
import ButtomBanner from '../../components/buttomBanner/ButtomBanner';
import GridLayout from '../../components/gridLayout/GridLayout';
import Planning from '../../components/planning/Planning';

const Home = () => {
  return (
    <>
         <Hero />
        <Slider />
        <Virtual />
        {/* <Products /> */}
        <GridLayout/>
        <Testimonial/>
        <Planning/>
        <ButtomBanner/>
    </>
  )
}

export default Home;