import React, { useRef,useState } from 'react';
import css from './Contact.module.css';
import emailjs from '@emailjs/browser';
import ContactImage from '../../assets/contact-us.jpeg';
import { Controller, useForm } from "react-hook-form";
import PhoneInputWithCountry from 'react-phone-number-input';


import GetTutor from '../../assets/contact-gettutor.webp';
import BecomeTutor from '../../assets/contact-becometutor.webp';

import { useNavigate } from 'react-router-dom';
import FormElementTwo from '../../components/formElement/FormElementTwo';
import ButtomBannerTwo from '../../components/buttomBanner/ButtomBannerTwo';




const Contact = () => {
  const navigate = useNavigate();
  const form = useRef();
  const [mobile, setMobile] = useState('');
  const [err, setErr] = useState('');



  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm(process.env.REACT_APP_KEY_SERVICE, process.env.REACT_APP_TEMPLATE_KEY, form.current, process.env.REACT_APP_USER_ID)
       
      .then((result) => {
          form.current.reset();
          setMobile('')
          console.log(result.text);
          console.log('message sent successfully');
          setErr('message sent successfully');

      }, (error) => {
          console.log(error.text);
      });
  };
  const {control, handleSubmit, formState:{errors}} = useForm({
    
    defaultValues: {
        first_name: '',
        lastName: '',
        mobile: '',
        email: '',
        message: ''
    }
});
  return (
    <div className='flex flex-col h-full bg-gradient-app-bg'>
                      <div className='flex relative w-full h-96 md:h-[23rem] xxxs:h-[13.2rem] sm:h-[20.5rem] iphone:h-[19.5rem] IphoneProMax:h-[17.5rem] mmmIphone:h-[17rem] xs:h-[15rem] xxs:h-[14.75rem]'>
                              <div className={css.bg_overlay}> 
                                  <h1>Our <strong> Expert Tutors</strong> will help <br/> you attain your highest <br/> possible score. </h1>
                              </div>
                              
                              <img src={ContactImage} alt='courses' className='w-full'/>
                    </div>


     
  {/* ******************************************************* Grid Layout **************************** */}

  <div className='flex flex-col  items-center justify-center bg-transparent'>

         {/* ******************************************************* Grid Title **************************** */}
          <div className='pt-[10rem] text-3xl md:pt-[8rem] xxs:pt-[4.5rem] xxs:pb-[0rem] md:text-2xl lg:text-[1.65rem] laptopmd:text-3xl iphone:text-[1.35rem] mmmIphone:text-[1.2rem] xs:text-[1.05rem] xxxs:text-[.83rem] xxxs:font-semibold xxxs:pt-[4.5rem] xxs:text-[.9rem] xxs:font-semibold text-white'> 
                How would you like to get in touch with us?
          </div>
       
         {/* ******************************************************* Grid Two Sections (Find a Tutor - Become a Tutor) **************************** */}
          <div class="flex h-[45rem] xxxs:w-[88%] md:h-[47rem] xxxs:h-[64rem] xxs:h-[70rem] items-center justify-center">  
              <div class="grid grid-cols-2 md:grid-cols-2 xxs:grid-cols-1 xxxs:grid-cols-1 xxxs:gap-[3rem] xxs:gap-[5rem] gap-[10rem] md:gap-[4rem] lg:gap-[6rem] laptopmd:gap-[10rem]">
                  {/* ******************************************************* Grid First Section (Find a Tutor) **************************** */}
                  <div class="group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
                      <div class="h-[27rem] xxxs:h-[24rem] w-96 md:h-[24rem] md:w-72 lg:w-[22rem] laptopmd:w-[26rem] laptopmd:h-[27rem] xxs:w-[20rem]">
                        <img class="h-full w-full object-cover transition-transform duration-500 group-hover:rotate-3 group-hover:scale-125" src={GetTutor} alt="" />
                      </div>
                      <div class="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
                      <div class="absolute md:w-72 lg:w-[22rem] laptopmd:w-[26rem] xxs:w-[20rem] bg-white bg-opacity-70  inset-0 flex translate-y-[60%] flex-col items-center justify-center px-9 text-center transition-all duration-500 group-hover:translate-y-0">
                        <h1 class="text-[2rem] pb-[10rem] xxxs:text-[1.35rem] xxs:text-[1.35rem] xs:text-[1.45rem] md:text-[1.6rem] md:pb-[7rem] laptopmd:text-[1.7rem] laptopmd:pb-[9.2rem] font-semibold text-blue-900">Find a tutor</h1>
                        <p class="mb-3 text-2xl md:text-xl xxs:text-[1.38rem] xs:text-[1.35rem]  laptopmd:text-[1.35rem]  text-blue-900 opacity-0 transition-opacity duration-300 group-hover:opacity-100">Please fill the callback form and our team will get back to you shortly.</p>
                      </div>
                  </div>
                  {/* ******************************************************* Grid Second Section (Become a Tutor) **************************** */}
                  <div class="group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
                      <div class="h-[27rem] xxxs:h-[24rem] w-96 md:h-[24rem] md:w-72 lg:w-[22rem] laptopmd:w-[26rem] laptopmd:h-[27rem] xxs:w-[20rem]">
                        <img class="h-full w-full object-cover transition-transform duration-500 group-hover:rotate-3 group-hover:scale-125" src={BecomeTutor} alt="" />
                      </div>
                      <div class="absolute  inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
                      <div class="absolute md:w-72 lg:w-[22rem] laptopmd:w-[26rem] xxs:w-[20rem] bg-white bg-opacity-70  inset-0 flex translate-y-[60%] flex-col items-center justify-center px-9 text-center transition-all duration-500 group-hover:translate-y-0">
                        <h1 class="text-[2rem] pb-[4.5rem] xxs:text-[1.35rem] xs:text-[1.45rem] md:text-[1.6rem] md:pb-[3.5rem] laptopmd:text-[1.7rem] laptopmd:pb-[8rem] font-semibold xxxs:text-[1.35rem] text-blue-900">Become a tutor</h1>
                        <p class="mb-3 text-2xl md:text-xl xxs:text-[1.35rem] xs:text-[1.35rem]  laptopmd:text-[1.35rem] text-blue-900  opacity-0 transition-opacity duration-300 group-hover:opacity-100">Are you interested in becoming a tutor on AIM TEACH? Please fill the callback form and our team will get back to you shortly.</p>
                      </div>
                  </div>
              </div>
            </div>
            {/* ******************************************************* End Of Grid Two Sections (Find a Tutor - Become a Tutor) **************************** */}
      </div>
{/* ******************************************************* End Of Grid Layout **************************** */}


    <div className={css.FormSection}>
      <div className={css.leftSideForm}>
          <span>Our Educational Consultants are ready to answer all your questions and recommend the best tutor for your learning needs and goals.</span>
      </div>

      <div className={css.rightSideForm}>
            <div className='flex absolute iphoneTwelve:top-[13.5rem] iphoneFifteen:top-[15.3rem] mmmIphone:top-[14rem] md:top-[19.8rem] md:left-[33%] lg:top-3 lg:left-[65%] laptopmd:left-[70%] laptopmd:top-7 top-[15rem] left-[23%] text-md font-medium text-blue-900'>{err}</div>
          <form ref={form} onSubmit={sendEmail} className='w-[30rem] laptopmd:w-[30rem] lg:w-[25rem] xxs:w-[100%]'>
                        <Controller
                            name='first_name'
                            control={control}
                            rules={{required: true}}
                            render={(field) => (
                                <FormElementTwo 
                                    type='text'
                                    name='first_name'
                                    label='First Name'
                                    placeholder='Please Enter Your First Name.' 
                                    fieldRef={field}
                                    hasError={errors.firstname?.type === 'required'} />
                            )}
                        />
                         <Controller
                            name='lastName'
                            control={control}
                            rules={{required: true}}
                            render={(field) => (
                                <FormElementTwo 
                                    type='text'
                                    name='lastName'
                                    label='Last Name'
                                    placeholder='Please Enter Your Last Name.' 
                                    fieldRef={field}
                                    hasError={errors.lastname?.type === 'required'} />
                            )}
                        />
                           <label className="block text-gray-700 text-sm font-bold mb-2">Phone Number</label>
                        <Controller
                            name='mobile'
                            control={control}
                            rules={{required: true}}
                            render={(field) => (
                                
                                <PhoneInputWithCountry
                                        defaultCountry="AE"
                                        name="mobile" // whatever your name for react-hook-form is
                                        control={control} // from react-hook-form
                                        onChange={(mobile) => setMobile(mobile)}
                                       
                                        numberInputProps={{
                                            className: 'form-group mb-1 form-control w-full px-3 py-1.5 text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none' // my Tailwind classes
                                        }}
                                />
                            )}
                        />
                        <Controller
                            name='email'
                            control={control}
                            rules={{required: true}}
                            render={(field) => (
                                <FormElementTwo 
                                    type='email'
                                    name='email'
                                    label='Email'
                                    placeholder='Enter email here...' 
                                    fieldRef={field}
                                    hasError={errors.email?.type === 'required'} />
                            )}
                        />

                        <Controller
                            name='message'
                            control={control}
                            rules={{required: true}}
                            render={(field) => (
                                <FormElementTwo 
                                    type='textarea'
                                    name='message'
                                    label='How can we assist you?'
                                    placeholder='How can we assist you?' 
                                    fieldRef={field}
                                    hasError={errors.message?.type === 'required'} />
                            )}
                        />
                        <div className='grid grid-cols-2 gap-x-6'>
                            <button onClick={() => navigate(-1)} className='px-3 py-2 bg-transparent hover:bg-blue-500 hover:text-white border-gray-600 border border-solid rounded-md' type='button'>Previous</button>
                            <button className='px-3 py-2 bg-blue-500 text-white hover:bg-white hover:text-colorSpecialBlue border-gray-600 border border-solid rounded-md' type='submit'>Submit</button>
                       

                        </div>
                    </form>
                   
      
   
      </div>
    </div>
    <ButtomBannerTwo/>
  </div>
  )
}

export default Contact;

