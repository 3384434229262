import KarenK from '../../assets/tutors/Kareen-K.png';
import EnasB from '../../assets/tutors/Enas-B.png';
import FerialAF from '../../assets/tutors/Ferial-AF.png';
import RazanH from '../../assets/tutors/Razan-H.png';
import NidalS from '../../assets/tutors/Nidal-S.png';
import AnastasiaG from '../../assets/tutors/Anastasia.jpg';
import RanaS from '../../assets/tutors/Rana-C.png';
import ZeinZ from '../../assets/tutors/Zeina-Z.png';
import DanaK from '../../assets/tutors/Dana-K.png';
import RaedD from '../../assets/tutors/Raed-D.png';
import NasabS from '../../assets/tutors/Nasab-S.png';
import PascalAK from '../../assets/tutors/Pascal-AK.png';
import MadonaJ from '../../assets/tutors/Madona-J.png';
import AyaA from '../../assets/tutors/Aya-A.png';
import RazanS from '../../assets/tutors/Razan-S.png';
import GhinwaA from '../../assets/tutors/Ghinwa-A.png';
import LoraF from '../../assets/tutors/Lora-F.png';
import LayalH from '../../assets/tutors/Layla-H.png';
import MayaJ from '../../assets/tutors/Maya-J.png';
import SereneAM from '../../assets/tutors/Serene-AM.png';


//DataInterface[]
export const data = [
  {
    id: 0,
    img: KarenK,
    name: 'Karen K.',
    job: 'Math Tutor',
    text: "I hold a Master's degree in Accounting and Auditing. I have been teaching SAT, math, and business courses for over three years. I'm exposed to multiple curricula like IB, and IGCSE.",
  },
  {
    id: 1,
    img: EnasB,
    name: 'Enas B.',
    job: 'Chemistry Tutor',
    text: "Graduated from the Lebanese University in 2008 with a bachelor degree in Bio Chemistry and a Teaching Diploma in Sciences. I have 11 years of experience teaching chemistry for cycles 3 and 4.",
  },

  {
    id: 2,
    img: FerialAF,
    name: 'Ferial AF.',
    job: 'Accounting Tutor',
    text: "I am a Financial Analyst and experienced Accountant with a bachelor degree in Banking and Finance. I'm exposed to multiple curricula. I tutored Accounting, Economics and Finance for students from grades 7 till 12.",
  },

  {
    id: 3,
    img: RazanH,
    name: 'Razan H.',
    job: 'CCE Tutor',
    text: "I am a Computer and Communication Engineer. I have been tutoring since 2019. I tutored Math, and Physics for students from grades 7 till 12.",
  },

  {
    id: 4,
    img: NidalS,
    name: 'Nidal S.',
    job: 'Math Tutor',
    text: "I hold a Master's Degree in Accounting & Auditing, and a Diploma in Technical Analysis. I'm exposed to multiple curricula. I tutored Math and Accounting for university students.",
  },

  {
    id: 5,
    img: AnastasiaG,
    name: 'Anastasia G.',
    job: 'Computer Science Tutor',
    text: "My courses will cover a range of fundamental topics including web development, and design with Figma. I aim to inspire and equip the next generation of young minds with essential skills in the ever-evolving world of technology.",
  },
  {
    id: 6,
    img: RanaS,
    name: 'Rana S.',
    job: 'Medical Lab Tutor',
    text: "BS in Medical Lab Technology from Notre Damme University graduated with GPA 3.7/4. Masters in Toxicology from American University for Science and Technology graduated with GPA 4/4 . ",
  },
  {
    id: 7,
    img: ZeinZ,
    name: 'Zeina Z.',
    job: 'Math & Statistics Tutor',
    text: "I have completed Applied Mathematics, Statistics at Lebanese University with French and Lebanese Bachelor's degree. I have been teaching Mathematics for 3 years as well as tutoring both math and statistics for 5 years.",
  },
  {
    id: 8,
    img: DanaK,
    name: 'Dana K.',
    job: 'SAT, Islets and Triple E Tutor',
    text: "I have a BS in English Literature, Teaching diploma, Masters of Education in teaching English and I am currently a PhD candidate at Saint Joseph university. I have been teaching SAT, IELTS, triple E and English courses for over 5 years now. ",
  },
  {
    id: 9,
    img: RaedD,
    name: 'Raed D.',
    job: 'Chemistry Tutor',
    text: "I have a Masters degree in Molecular Chemistry. Tutoring encourage real enthusiasm in the field of chemistry by using cutting-edge teaching methods, IB System to help the students understand complex ideas ",
  },
  {
    id: 10,
    img: NasabS,
    name: 'Nasab S.',
    job: 'Chemistry Tutor',
    text: "I hold a Bachelor degree in Chemistry and teaching diploma. I tutor chemistry, English and Math. I did an internship at the American Community School in Beirut where I taught Chemistry following the IB curriculum.",
  },
  {
    id: 11,
    img: PascalAK,
    name: 'Pascal AK.',
    job: 'Biology Tutor',
    text: "I am a highly resolute and competent Master’s graduate in Microbiology and Immunology from the American University of Beirut with a BS in biology from the Lebanese University. I have 5 years of experience in tutoring Biology for cycles 3 and 4.",
  },
  {
    id: 12,
    img: MadonaJ,
    name: 'Madona J.',
    job: 'Biology Tutor',
    text: "I have a bachelor's degree in biology and Vocational Masters in Education. I have been a very efficient teacher assisting hundreds of grad students for 13 years with a lot of dedication and passion",
  },
  {
    id: 13,
    img: AyaA,
    name: 'Aya A.',
    job: 'Science Tutor',
    text: "A bachelor graduate in Life and Earth Sciences, holding a Master 1 degree in applied animal biology and pursuing a Master 2 degree in Molecular Diagnostics. I tutored Chemistry, Biology and Physics for 7 years.",
  },
  {
    id: 14,
    img: RazanS,
    name: 'Razan S.',
    job: 'Arabic Tutor',
    text: "Arabic teacher with Bachelor Degree in Arabic literature. I have been teaching for 10 years cycles 2 and 3 in public and private schools. I have 5 years of experience in private tutoring one-to-one and online.",
  },
  {
    id: 15,
    img: GhinwaA,
    name: 'Ghinwa A.',
    job: 'Arabic Tutor',
    text: "Arabic Teacher with 25 years of experience in education and teaching Arabic for all cycles, and a former coordinator in private and public schools. Participation in preparing books for the third episode (Dar Socrates)",
  },
  {
    id: 16,
    img: LoraF,
    name: 'Lora F.',
    job: 'French & Spanish Tutor',
    text: "Talented French and Spanish teacher with a bachelor degree in LVA (foreign languages) Spanish. A Master's degree in intercultural communications (Spanish). Expert in tutoring and delivering private lessons with five years of experience.",
  },
  {
    id: 17,
    img: LayalH,
    name: 'Layal H.',
    job: 'English Tutor',
    text: "Bachelor degree in English Language and Literature. English Instructor and coordinator. Over the course of my 10+ years career, I've developed skills set directly relevant to teach English as foreign language for cycle 3 and 4.",
  },
  {
    id: 18,
    img: MayaJ,
    name: 'Maya J.',
    job: 'English Tutor',
    text: "I have a bachelor degree in English language and Literature. I have experience in teaching English as a foreign Language for more than 10 years for cycles 2 and 3. I have taught English for IB, French, American and Lebanese curricula.",
  },

  {
    id: 20,
    img: SereneAM,
    name: 'Serene AM.',
    job: 'Business Tutor',
    text: "I am a Public Health professional with a diploma, complemented by certificates in digital and media literacy, strategies for responsible leadership, and political literacy through non-formal learning.",
  },
];