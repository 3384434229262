import { Typography } from "@material-tailwind/react";
import { Link, useNavigate } from "react-router-dom";

 
const LINKS = [
  {
    title: "Services",
    items: [{Subjects: 'School Subjects', attach:'/our-services',mobile: ''}, {Subjects:'University Courses', attach:'/university-courses',mobile:''}, {Subjects: 'Master Programs', attach: '/learn-a-skill',mobile:''}, {Subjects: 'Prep Tests', attach: '/prep-tests',mobile:''}],
  },
  {
    title: "",
    items: [""],
  },
  {
    title: "Location",
    items: [{Subjects:"Dubai - UAE",attach:'', mobile:"Mobile: 0585305523", whatsapp:"Whatsapp: 0503406781"}],
  },
];
 
const currentYear = new Date().getFullYear();
 
const  Footer = () =>{
  const navigate = useNavigate();
  return (
    <footer className="relative w-full bg-transparent text-white pt-7">
      <div className="mx-auto w-full max-w-7xl px-8">
        <hr className="pb-5"/>
        <div className="grid grid-cols-1 justify-between gap-4 md:grid-cols-2">
          <Typography variant="h5" className="mb-6">
            <span onClick={() => navigate('/')} className="tracking-wider cursor-pointer">AIM TEACH</span>
            <div className="flex gap-4 text-blue-gray-900 sm:justify-center pb-20">
          
          <Typography as="a" href="https://www.instagram.com/aimteach" target="_blank" className="flex laptopmd:left-[8.6rem] pr-4 absolute xxxs:left-[1.6rem] xxs:left-[1.6rem] left-[8.6rem] specialSize13:left-[4.6rem] mt-2 opacity-80 transition-opacity hover:opacity-100">
            <svg className="h-7 w-7" fill="orange" viewBox="0 0 24 24" aria-hidden="true">
              <path
                fill-rule="evenodd"
                d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                clip-rule="evenodd"
              />
            </svg>
            <span className="ml-1">@aimteach</span>
          </Typography>
       
       
        
        </div>
          </Typography>
          <div className="grid grid-cols-3 justify-between gap-4">
            {LINKS.map(({ title, items }) => (
              <ul key={title}>
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="mb-3 font-medium opacity-40"
                >
                  {title}
                </Typography>
                {items.map((link) => (
                  <li key={link.Subjects} className="sm:text-xs">
                  <Link to={link.attach}>
                    <Typography
                      as="a"
                      href=''
                      color="white"
                      className="py-1.5 font-normal text-[.9rem] transition-colors hover:text-blue-gray-900"
                    >
                      {link.Subjects}<br/><br/>
                       <span className="pt-10">{link.mobile}</span><br/><br/>
                       <span className="pt-10">{link.whatsapp}</span> 
                    </Typography>
                    </Link>
                  </li>
                ))}
              </ul>
            ))}
          </div>
        </div>
        <div className="mt-12 flex w-full flex-col items-center justify-center border-t border-blue-gray-50 py-4 md:flex-row md:justify-between">
          <Typography
            variant="small"
            className="mb-4 text-center font-normal text-blue-gray-900 md:mb-0"
          >
            &copy; 2023 <a href="">AIM TECHNOLOGY</a>. All
            Rights Reserved.
            </Typography>
    
        </div>
      </div>
    </footer>
  );
};
export default Footer;