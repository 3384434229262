import css from './AllCourses.module.css';
import {masterPrograms} from '../../data/courses';
import CoursesImage from '../../assets/master-programs.jpeg';
import Planning from '../../components/planning/Planning';
import ButtomBanner from '../../components/buttomBanner/ButtomBanner';
import Description from '../../components/description/Description';
import { Link } from 'react-router-dom';


const MasterPrograms = () => {

  return (
    <div className='flex flex-col w-full h-full bg-gradient-app-bg'>
          {/* Right Section Image */}
          <div className='flex relative w-full h-96 xxxs:h-[13.3rem] md:h-[23rem] sm:h-[20.5rem] iphone:h-[19.5rem] IphoneProMax:h-[17.5rem] mmmIphone:h-[17rem] xs:h-[15rem] xxs:h-[13.75rem]'>
                <div className={css.bg_overlay_master}> 
                    <h1 className={css.master_h1}>Students without technical <br/>backgrounds are now able to <br/> <strong> Learn Programming</strong> </h1>
                </div>
                
                <img src={CoursesImage} alt='courses' className='w-full'/>
            </div>
            <Description title="Coding Skills Will Be Essential in Many Career Paths in The Near Future. Coding skills are a must-have in the new digital economy."
                description="Programmers are currently in high demand and will be even more so in the near future."
            />
            <div className={css.repair_main_banner}>
                <h2>Learn a Skill</h2>
                <div className={css.progress_master}>
                    <div className={css.progress_bar} role='progressbar' style={{width:'40%'}}></div>
                </div>
                
              

            {/* Left Section Gallery of Courses */}
            <div className={css.leftSide}>
                {
                    masterPrograms.map((course,i) => {
                        return(
                       <Link to='/contact-form'>
                        <div className={css.university_course}>
                            <img src={course.icon} alt='logo'/>
                            <div>{course.name}</div>
                        </div>
                        </Link>     
                    )})
                }
            </div>
            </div>
            <Planning />
            <ButtomBanner/>
    </div>
  )
}

export default MasterPrograms;