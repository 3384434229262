import css from './AllCourses.module.css';
import {UniversityCoursesData} from '../../data/courses';
import CoursesImage from '../../assets/all-courses.jpeg';
import Planning from '../../components/planning/Planning';
import ButtomBanner from '../../components/buttomBanner/ButtomBanner';
import Description from '../../components/description/Description';
import { Link } from 'react-router-dom';


const UniversityCourses = () => {

  return (
    <div className='flex flex-col w-full h-full bg-gradient-app-bg'>
          {/* Right Section Image */}
          <div className='flex relative w-full h-96 md:h-[23rem] xxxs:h-[13.3rem] sm:h-[20.5rem] iphone:h-[19.5rem] IphoneProMax:h-[17.5rem] mmmIphone:h-[17rem] xs:h-[15rem] xxs:h-[13.75rem]'>
                <div className={css.bg_overlay}> 
                    <h1 className='pl-1'>A <strong> dedicated tutor </strong><br/> every step of the way. </h1>
                </div>
                
                <img src={CoursesImage} alt='courses' className='w-full'/>
            </div>

            <Description title="If you need help accomplishing your long-term academic goals, you can turn to AIM TEACH for convenient, reliable in-home and online college tutoring services."
                description="We understand that every student learns differently, which is why we take the time to match each student with a tutor who has the right teaching style for their needs."
            />

            <div className={css.repair_main_banner}>
                <h2>What is you major?</h2>
                <div className={css.progress_university}>
                    <div className={css.progress_bar} role='progressbar' style={{width:'45%'}}></div>
                </div>
                
              

            {/* Left Section Gallery of Courses */}
            <div className={css.leftSide}>
                {
                    UniversityCoursesData.map((course,i) => {
                        return(
                         <Link to='/contact-form'>
                            <div className={css.university_course}>
                                <img src={course.icon} alt='logo'/>
                                <div>{course.name}</div>
                            </div>
                        </Link>   
                    )})
                }
            </div>
            </div>
            <Planning />
            <ButtomBanner/>
    </div>
  )
}

export default UniversityCourses;