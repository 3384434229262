import mathIcon from '../assets/math_icon.svg';
import pythicsIcon from '../assets/physics-icon.svg';
import chemistryIcon from '../assets/chemistry-icon.svg';
import biologyIcon from '../assets/biology-icon.svg';
import englishIcon from '../assets/english-icon.svg';
import arabicIcon from '../assets/arabic-icon.png';
import homeworkIcon from '../assets/homework-icon.svg';
import otherIcon from '../assets/other-icon.svg';
import languagesIcon from '../assets/languages-icon.png';
import prepareIcon from '../assets/PREP_TESTS-icon.svg';
import universityIcon from '../assets/UNI_COURSES-icon.svg';
import otherCoursesIcon from '../assets/other_courses-icon.svg';
import computerIcon from '../assets/COMPUTER-icon.svg';
import personalIcon from '../assets/WORKSHOPS-icon.svg';
import musicIcon from '../assets/MUSIC-icon.svg';
import frenchIcon from '../assets/french-icon.svg';
import spanishIcon from '../assets/spanish-icon.svg';
import italianIcon from '../assets/italian-icon.svg';
import businessIcon from '../assets/web copy.png';
import engineerIcon from '../assets/mobile copy.png';
import computerScienceIcon from '../assets/backend copy.png';
import ManagementIcon from '../assets/creator copy.png';



export const Courses= [{
    icon:mathIcon,
    name: 'Math'
   
},
{
    icon:pythicsIcon,
    name: 'Physics'
   
},
{
    icon:chemistryIcon,
    name: 'Chemistry'
   
},
{
    icon:biologyIcon,
    name: 'Biology'
   
},
{
    icon:englishIcon,
    name: 'English'
   
},
{
    icon:arabicIcon,
    name: 'Arabic'
   
},
{
    icon:languagesIcon,
    name: 'French'
   
},
{
    icon:languagesIcon,
    name: 'Spanish'
   
},
{
    icon:homeworkIcon,
    name: 'Homework Help'
   
},


{
    icon:prepareIcon,
    name: 'Prep Tests'
   
},

{
    icon:musicIcon,
    name: 'Music'
   
},
]


export const OthCourses= [
    {
        icon:computerIcon,
        name: 'Programming & AI'
       
    },
    {
        icon:personalIcon,
        name: 'Personalized Workshops'
       
    },
    {
        icon:musicIcon,
        name: 'Music'
       
    },
    {
        icon:otherCoursesIcon,
        name: 'Other'
       
    },



]

export const LanguageCourses= [
    {
        icon:universityIcon,
        name: 'English'
       
    },
    {
        icon:arabicIcon,
        name: 'Arabic'
       
    },
    {
        icon:frenchIcon,
        name: 'French'
       
    },
    {
        icon:spanishIcon,
        name: 'Spanish'
       
    },
    {
        icon:italianIcon,
        name: 'Italian'
       
    },
    {
        icon:languagesIcon,
        name: 'Other Languages'
       
    },


]

export const UniversityCoursesData= [
{
    icon:mathIcon,
    name: 'Mathematics and Sciences'
    
},
{
    icon:pythicsIcon,
    name: 'Mechanical & Industrial Engineering'
    
},

{
    icon:biologyIcon,
    name: 'Biological & Biomedical Sciences'
    
},
{
    icon:businessIcon,
    name: 'Business and Management'
    
},
{
    icon:computerScienceIcon,
    name: 'Computer & Information Sciences'
    
},
{
    icon:computerScienceIcon,
    name: 'Computer Communication Engineering'
    
},
{
    icon:languagesIcon,
    name: 'Foreign Languages, Literatures, and Linguistics'
    
},

{
    icon:computerScienceIcon,
    name: 'Electrical Engineering & Computing'
   
},
{
    icon:computerScienceIcon,
    name: 'Mechanical Engineering'
   
},
{
    icon:businessIcon,
    name: 'Accounting & Finance'
},
{
    icon: businessIcon,
    name: 'Economics'
},


{
    icon:businessIcon,
    name: 'Science and Liberal Arts'
   
},

{
    icon:homeworkIcon,
    name: 'Assignment & Project Help'
   
},


{
    icon:prepareIcon,
    name: 'Prep Tests'
   
},


]





export const masterPrograms= [
{
    icon:businessIcon,
    name: 'Comprehensive Java'
    
},
{
    icon:businessIcon,
    name: 'Python Django'
    
},

{
    icon:businessIcon,
    name: 'Full-Stack Web Development'
    
},
{
    icon:businessIcon,
    name: 'Flutter Application Development'
    
},
{
    icon:businessIcon,
    name: 'Data Science & R Programming'
    
},

{
    icon:businessIcon,
    name: 'Cloud Architect'
    
},

{
    icon:businessIcon,
    name: 'Automation Testing'
   
},

{
    icon:businessIcon,
    name: 'Robotics & Microcontrollers'
   
},
{
    icon:businessIcon,
    name: 'Data Analytics'
   
},

{
    icon:businessIcon,
    name: 'Big Data Architect'
   
},



]



export const prepTests= [
    {
        icon:otherCoursesIcon,
        name: 'SAT'
        
    },
    {
        icon:otherIcon,
        name: 'IELTS'
        
    },
    
    {
        icon:prepareIcon,
        name: 'IGCSE'
        
    },

    {
        icon:otherCoursesIcon,
        name: 'A Level'
        
    },
    
    {
        icon:homeworkIcon,
        name: 'IB Diploma'
        
    },    
    
    
    ]