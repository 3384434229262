import Marquee from 'react-fast-marquee';

const Announcement = () => {
  return (
    <div className="announcement">
            <Marquee speed={60} gradient={false} pauseOnHover>
            <div className="announcement-text text-1">
                  <span>Ramadan Offers 2024</span>
                  <span>Get up to 60% off during Ramadan</span>
                 <span>Expert tutors for your learning needs </span>
                 <span>Personal Tutoring, Done Right</span>
                 <span>Satisfaction Guaranteed</span>
                 <span>أفضل العروض والخصومات التي يمكنك الاستفادة منها خلال شهر رمضان 2024</span>
                 <span>Ask About Our Speical Offers!</span>
                 <span>Get up to 60% off during Ramadan</span>
                 <span>احصل على خصم يصل إلى 60% خلال شهر رمضان</span>
                 <span>عروض وخصومات خاصة على كافة المواد ولجميع الصفوف</span>

            </div>
            <div className="announcement-text text-2">
                <span>Ramadan Offers 2024</span>
                 <span>Premium tutorial services for all ages</span>
                 <span>The Best Offers and Discounts You Can Take Advantage of During Ramadan 2024</span>
                 <span>Tutoring - Now!</span>
                 <span>Ramadan Offers 2024</span>
                 <span>اسأل عن باقة الـ 12 ساعة لجميع الدورات والمواد والمناهج التعليمية واحصل على أفضل الأسعار</span>
                 <span>أفضل العروض والخصومات التي يمكنك الاستفادة منها خلال شهر رمضان 2024</span>
                 


            </div>
            </Marquee>
    </div>
  )
}

export default Announcement
