import css from './AllCourses.module.css';
import {Courses} from '../../data/courses';
import CoursesImage from '../../assets/all-courses-kids.jpeg';
import Planning from '../../components/planning/Planning';
import ButtomBanner from '../../components/buttomBanner/ButtomBanner';
import Description from '../../components/description/Description';
import { Link } from 'react-router-dom';


const AllCourses = () => {

  return (
    <div className='flex flex-col w-full h-full bg-gradient-app-bg'>
          {/* Right Section Image */}
            <div className='flex relative w-full h-96 xxxs:h-[16rem] md:h-[23rem] sm:h-[20.5rem] iphone:h-[19.5rem] IphoneProMax:h-[17.5rem] mmmIphone:h-[17rem] xs:h-[15rem] xxs:h-[13.75rem]'>
                <div className={css.bg_overlay}> 
                    <h1>We Match <br/><strong>Our Tutors </strong> To Your<br/>Child's Needs. </h1>
                </div>
                
                <img src={CoursesImage} alt='courses' className='w-full'/>
            </div>
            <Description title="Our tutoring programs in Dubai is aligned to major curricula with focus on the UK National Curriculum, GCSE, A Level, PYP, MYP, IB, and the American Curriculum."
                description="We match our students with the right tutor based on their learning needs and objectives."
            />
            <div className={css.repair_main_banner}>
                <h2>What do you need help with?</h2>
                <div className={css.progress}>
                    <div className={css.progress_bar} role='progressbar' style={{width:'60%'}}></div>
                </div>
                
              

            {/* Left Section Gallery of Courses */}
            <div className={css.leftSide}>
                {
                    Courses.map((course,i) => {
                        return(
                        <Link to='/contact-form'>
                        <div className={css.course}>
                            <img src={course.icon} alt='logo'/>
                            <div>{course.name}</div>
                        </div>
                        </Link>    
                    )})
                }
            </div>
            </div>
            <Planning />
            <ButtomBanner/>
    </div>
  )
}

export default AllCourses;