import css from './AllCourses.module.css';
import {prepTests} from '../../data/courses';
import CoursesImage from '../../assets/prep-tests.jpeg';
import Planning from '../../components/planning/Planning';
import ButtomBanner from '../../components/buttomBanner/ButtomBanner';
import Description from '../../components/description/Description';
import { Link } from 'react-router-dom';


const PrepTests = () => {

  return (
    <div className='flex flex-col w-full h-full bg-gradient-app-bg'>
          {/* Right Section Image */}
          <div className='flex relative w-full h-96 xxxs:h-[13.1rem] md:h-[23rem] sm:h-[20.5rem] iphone:h-[19.5rem] IphoneProMax:h-[17.5rem] mmmIphone:h-[17rem] xs:h-[15rem] xxs:h-[13.75rem]'>
                <div className={css.bg_overlay_tests}> 
                    <h1>Our <strong> Expert Tutors</strong> will help <br/> you attain your highest <br/> possible score. </h1>
                </div>
                
                <img src={CoursesImage} alt='courses' className='w-full'/>
            </div>
            <Description title='Our experienced tutors will help you achieve your best possible score by providing personalized instruction and guidance.' 
            description='Our tutors will teach you efficient tips to manage your time during the test and how to improve your score.'/>
            <div className={css.repair_main_banner}>
                <h2>Practice and Preparation</h2>
                <div className={css.progress_tests}>
                    <div className={css.progress_bar} role='progressbar' style={{width:'40%'}}></div>
                </div>
                
         

            {/* Left Section Gallery of Courses */}
            <div className={css.leftSide}>
                {
                    prepTests.map((course,i) => {
                        return(
                       <Link to='/contact-form'>
                        <div className={css.course}>
                            <img src={course.icon} alt='logo'/>
                            <div>{course.name}</div>
                        </div>
                        </Link>     
                    )})
                }
            </div>
            </div>
            <Planning />
            <ButtomBanner/>
    </div>
  )
}

export default PrepTests;