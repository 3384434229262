import './App.css';
import {Routes, Route} from 'react-router-dom';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import Home from './pages/home/Home';
import AllCourses from './pages/allCourses/AllCourses';
import GridLayout from './components/gridLayout/GridLayout';
import Contact from './pages/contact/Contact';
import ContactForm from './pages/contactform/ContactForm';
import ScrollToTop from './components/scrollTop/ScrollTop';
import UniversityCourses from './pages/allCourses/UniversityCourses';
import MasterPrograms from './pages/allCourses/MasterPrograms';
import PrepTests from './pages/allCourses/PrepTests';
import Announcement from './components/Announcement';





function App() {
  return (
    <div className="App">
        <ScrollToTop/>
        <Header />
        <Announcement/>
        <Routes>

            <Route path='/' element={<Home />} />
            <Route path='/aimteach' element={<Home />} />
            <Route path='/contact-us' element={<Contact />} />
            <Route path='/our-services' element={<AllCourses />} />
            <Route path='/university-courses' element={<UniversityCourses />} />
            <Route path='/learn-a-skill' element={<MasterPrograms />} />
            <Route path='/prep-tests' element={<PrepTests />} />
          
            <Route path='/grid-layout' element={<GridLayout/>}/>
            <Route path='/contact-form' element={<ContactForm/>}/>

            
        </Routes>
        
        <Footer/>
    </div>
  );
}

export default App;
