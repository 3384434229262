import React from 'react';
import studentInClass from '../../assets/students_in_class.png';
import smilingYoungWoman from '../../assets/smiling_young_woman.jpeg';
import doingExam from '../../assets/row_students_doing_exam.jpeg';
import { useNavigate } from 'react-router-dom';

const GridLayout = () => {
  const navigate = useNavigate();
  return (
 <div className='flex flex-col items-center bg-white justify-center samsungBig:pb-[7rem] pb-14'>
 <div className=' xxxs:pt-[8rem] laptopmd:pb-[2rem] laptopmd:pt-[10rem] xxxs:pb-[3rem] xs:pb-[3rem] xs:pt-[6rem] xxs:pt-[4.7rem] xxs:pb-[3rem] samTNO:pt-[4.7rem] samTNO:pb-[3rem] samTNT:pt-[4.7rem] samTNT:pb-[3rem] iphoneTwelve:pt-[4.7rem] lg:pt-[10rem] lg:pb-[2rem] lg:text-[2.1rem] mmmIphone:pt-[5.7rem] sm:pt-[6rem] sm:pb-[4.5rem] sm:text-[2rem] mmmIphone:pb-[5rem] iphoneTwelve:pb-[3rem] xss:pt-[4.7rem] xss:pb-[3rem] iphoneFifteen:pt-[4.7rem] iphoneFifteen:pb-[3rem] samsungBig:pt-[4.7rem] samsungBig:pb-[3rem] xs:text-[1.9rem]  mxs:pt-[5rem] msx:text-[1.65rem]  pt-[4rem] text-blue-900 text-[2rem]'>Our<strong> Services</strong>
 <hr className='h-[2px] lg:ml-[4.3rem] lg:w-[9rem] sm:w-[8.6rem] sm:ml-[4rem] xs:ml-[4.15rem] xs:w-[8rem]  ml-[4.15rem] mt-1 bg-red-900 w-[8.5rem] border-0 dark:bg-red-700'/>
       </div>
    
<div class="flex min-h-screen items-center justify-center pb-14 xs:px-[1rem] xxxs:px-[1rem] xxs:px-[1rem] samTNO:px-[1rem] samTNT:px-[1rem] laptop:px-8">
  <div class="grid grid-cols-1 gap-20 md:grid-cols-2 lg:grid-cols-3 lg:py-0 lg:gap-10 laptopmd:pt-0 tabletlg:py-10 xs:py-7 xxs:py-5 xxx:py-5 mxs:py-5">
    <div class="group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
      <div class="h-[27rem] w-96  laptopmd:w-[23rem] laptop:h-[24rem] laptop:w-80 tabletlg:h-[22rem] tabletlg:w-72 xs:px-3 xxs:px-5 xxxs:px-5">
        <img class="h-full  w-full object-cover transition-transform duration-500 group-hover:rotate-3 group-hover:scale-125" src={studentInClass} alt="" />
      </div>
      <div class="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
      <div class="absolute bg-blue-900  bg-opacity-90 inset-0 flex translate-y-[70%] flex-col items-center justify-center px-9 text-center transition-all duration-500 group-hover:translate-y-0">
        <h1 class="text-[2rem] lg:text-[1.4rem] lg:font-medium lg:mb-[7rem] xs:text-[1.5rem] xs:font-medium xs:mb-[9rem] xxs:text-[1.5rem] xxs:font-medium xxs:mb-[9rem] samTNO:text-[1.5rem] samTNO:font-medium samTNO:mb-[9rem] samTNT:text-[1.6rem] iphoneTwelve:text-[1.6rem] xxxs:text-[1.5rem] xxxs:font-medium xxxs:mb-[9rem] xss:text-[1.6rem] samsungBig:text-[1.6rem] iphoneFifteen:text-[1.6rem] samTNT:font-medium samTNT:mb-[9rem] iphoneTwelve:font-medium iphoneTwelve:mb-[9rem] xss:font-medium xss:mb-[9rem] iphoneFifteen:font-medium laptopmd:mb-[7.5rem] iphoneFifteen:mb-[9rem] samsungBig:font-medium samsungBig:mb-[9rem] sm:text-[1.8rem] mb-[5.2rem] font-semibold text-white">School Subjects</h1>
        <p class="p-3 mb-3 text-lg text-white xs:text-[1.2rem] xxxs:text-[1.2rem] xxs:text-[1.2rem] samTNO:text-[1.2rem] samTNT:text-[1.2rem] iphoneTwelve:text-[1.2rem] xss:text-[1.2rem] iphoneFifteen:text-[1.2rem] lg:text-[1.2rem] samsungBig:text-[1.2rem] opacity-0 transition-opacity duration-300 group-hover:opacity-100"> Math, Physics, Chemistry, Biology, Homework Help & more.</p>
        <button onClick={() => navigate('/our-services')} class="rounded-full  hover:bg-transparent hover:text-blue-900 hover:border hover:font-semibold bg-gradient-blue py-2 px-3.5 font-com text-sm capitalize text-white shadow shadow-black/60">FIND YOUR SUBJECT</button>
      </div>
    </div>
    <div class="group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
      <div class="h-[27rem] w-96 laptop:h-[24rem]  laptopmd:w-[23rem] laptop:w-80 tabletlg:h-[22rem] tabletlg:w-72 xs:px-3 xxs:px-5 xxxs:5rem">
        <img class="h-full w-full object-cover transition-transform duration-500 group-hover:rotate-3 group-hover:scale-125" src={smilingYoungWoman} alt="" />
      </div>
      <div class="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
      <div class="absolute bg-blue-900 bg-opacity-90  inset-0 flex translate-y-[70%] flex-col items-center justify-center px-9 text-center transition-all duration-500 group-hover:translate-y-0">
        <h1 class="text-[2rem] mb-[4rem] lg:text-[1.4rem] lg:font-medium lg:mb-[5rem] xs:text-[1.5rem] xs:font-medium xs:mb-[7.5rem] xxs:text-[1.5rem] xxs:font-medium xxs:mb-[7.5rem] samTNO:text-[1.5rem] samTNO:font-medium samTNO:mb-[7.5rem] samTNT:text-[1.6rem] iphoneTwelve:text-[1.6rem] xxxs:text-[1.5rem] xxxs:font-medium xxxs:mb-[7.5rem] xss:text-[1.6rem] samTNT:font-medium samTNT:mb-[7.5rem] xss:font-medium xss:mb-[7.5rem] iphoneFifteen:text-[1.6rem] iphoneTwelve:font-medium iphoneTwelve:mb-[7.5rem] iphoneFifteen:font-medium iphoneFifteen:mb-[7.5rem] laptopmd:mb-[6rem] samsungBig:text-[1.6rem] samsungBig:font-medium samsungBig:mb-[7.3rem]  sm:text-[1.8rem] font-semibold text-white">University Courses</h1>
        <p class="mb-3 text-lg text-white lg:text-[1.2rem] xs:text-[1.2rem] xxxs:text-[1.2rem] xxs:text-[1.2rem] samTNO:text-[1.2rem] samTNT:text-[1.2rem] xss:text-[1.2rem] iphoneTwelve:text-[1.2rem] iphoneFifteen:text-[1.2rem] samsungBig:text-[1.2rem] opacity-0 transition-opacity duration-300 group-hover:opacity-100">Computer Science, Engineering, Economics, Financial Accounting, Business Administration, Psychology & more.</p>
        <button onClick={() => navigate('/university-courses')} class="rounded-full bg-gradient-blue hover:bg-transparent hover:text-blue-900 hover:border hover:font-semibold py-2 px-3.5 font-com text-sm capitalize text-white shadow shadow-black/60">FIND YOUR COURSE</button>
      </div>
    </div>
    <div class="group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
      <div class="h-[27rem] w-96 laptop:h-[24rem] laptopmd:w-[23rem] laptop:w-80 tabletlg:h-[22rem] tabletlg:w-72 xs:px-3 xxs:px-5 xxxs:7rem">
        <img class="h-full w-full object-cover transition-transform duration-500 group-hover:rotate-3 group-hover:scale-125" src={doingExam} alt="" />
      </div>
      <div class="absolute  inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
      <div class="absolute bg-blue-900  bg-opacity-90  inset-0 flex translate-y-[70%] flex-col items-center justify-center px-9 text-center transition-all duration-500 group-hover:translate-y-0">
        <h1 class="text-[2rem] mb-[6rem] lg:text-[1.4rem] lg:font-medium lg:mb-[8rem] xs:text-[1.5rem] xs:font-medium xs:mb-[7.5rem] xxxs:text-[1.5rem] xxxs:font-medium xxxs:mb-[7.5rem] xxs:text-[1.5rem] xxs:font-medium xxs:mb-[7.5rem] samTNO:text-[1.5rem] samTNO:font-medium samTNO:mb-[7.5rem] iphoneTwelve:text-[1.6rem] iphoneTwelve:font-medium iphoneTwelve:mb-[7.5rem] xss:text-[1.6rem] xss:font-medium xss:mb-[7.5rem] iphoneFifteen:text-[1.6rem] samTNT:text-[1.6rem] samTNT:font-medium samTNT:mb-[7.5rem] laptopmd:mb-[7.5rem] iphoneFifteen:font-medium iphoneFifteen:mb-[7.5rem]  samsungBig:text-[1.6rem] samsungBig:font-medium samsungBig:mb-[8.3rem]  sm:text-[1.8rem] font-semibold text-white">Prep Tests</h1>
        <p class="mb-3 text-lg text-white lg:text-[1.2rem] xs:text-[1.2rem] xxxs:text-[1.2rem] xxs:text-[1.2rem] samTNO:text-[1.2rem] samTNT:text-[1.2rem] iphoneTwelve:text-[1.2rem] xss:text-[1.2rem] iphoneFifteen:text-[1.2rem]  samsungBig:text-[1.2rem] py-8  opacity-0 transition-opacity duration-300 group-hover:opacity-100">SAT, IELTS, IGCSE, GMAT, A-Level & more.</p>
        <button onClick={() => navigate('/prep-tests')} class="rounded-full bg-gradient-blue py-2 px-3.5 font-com text-sm capitalize hover:bg-transparent hover:text-blue-900 hover:border hover:font-semibold hover:border-blue-900 text-white shadow shadow-black/60">CHOOSE YOUR TEST</button>
      </div>
    </div>
  </div>

</div>

</div>   
  )
}

export default GridLayout;