import React from 'react';
import ButtomBannerImg from '../../assets/bottom_banner.jpeg';
import { useNavigate } from 'react-router-dom';


const ButtomBanner = () => {
  const navigate = useNavigate();
  return (
    <div className='flex relative text-center justify-center lg:h-[16rem] xxxs:h-[12rem] samsungBig:h-[12rem] xxs:h-[11rem] h-[20rem] laptopmd:h-[20rem]'>
            
            <img src={ButtomBannerImg} alt='bottom-banner' />
        
        <div className='flex flex-col absolute samTNT:right-[26%] iphoneTwelve:right-[27%] iphone:right-[31%] xss:right-[27%] iphoneFifteen:right-[25%] samsungBig:right-[25%] xxs:bottom-[70%] md:bottom-[60%] bottom-[50%] laptopmd:right-[38%] right-[30%]'>
            <span className='font-medium mmmIphone:text-[1.1rem] xxxs:text-[.95rem] samTNT:text-[0.95rem] xss:text-[.95rem] iphoneTwelve:text-[0.95rem] iphoneFifteen:text-[1rem] samsungBig:text-[1rem] xxs:text-[.85rem] xs:text-[.92rem] sm:text-[1.25rem] iphone:text-[1.25rem] md:text-[1.5rem] lg:text-[2rem] text-blue-800'>Ready to start your learning journey?</span>
            <button onClick={() => navigate("/contact-form")} className='absolute md:bottom-[-4.7rem] bottom-[-7rem] xxxs:bottom-[-4rem] iphoneFifteen:text-[.92rem] xxxs:left-[1rem] text-white rounded-xl xxxs:text-[0.7rem] xxs:bottom-[-5rem] xxs:font-normal md:left-[25%] hover:bg-transparent hover:transition-all hover:ease-in-out hover:text-pink-600 hover:font-bold hover:border hover:border-solid hover:border-pink-600 lg:left-[35%] xxxs:px-[0.7rem] xxxs:py-[.6rem] xxs:px-3 xxs:py-2 md:px-5 md:py-3 px-6 py-4 bg-pink-600'>GET A FREE TRIAL SESSION</button>
        </div>
        
    </div>
  )
}

export default ButtomBanner;