import React from 'react';
import css from './Hero.module.css';
import HeroImg from '../../assets/aim-merci.png';
import {BiBookReader} from 'react-icons/bi';
import {BsArrowRight} from 'react-icons/bs';
import {motion} from 'framer-motion';
import { Link } from 'react-router-dom';
import Whatsapp from '../whatsapp/Whatsapp';
import WhatsappSmallScreens from '../whatsapp/WhatsappSmallScreens';
import WhatsappFourOneFour from '../whatsapp/WhatsappFourOneFour';
import WhatsappThreeNineThree from '../whatsapp/WhatsappThreeNineThree';
import WhatsappThreeSevenFive from '../whatsapp/WhatsappThreeSevenFive';
import WhatsappThreeSixZero from '../whatsapp/WhatsappThreeSixZero';
import WhatsappMain from '../whatsapp/WhatsappMain';
import WhatsappThirteen from '../whatsapp/WhatsappThirteen';
import WhatsappAlf from '../whatsapp/WhatsappAlf';

const Hero = () => { 


  const transition = {duration: 3, type: 'spring'}


  return (
    <div className={css.container}>

    {/* Left Side */}
        <div className={css.h_sides}>
            <span className={css.text1}>Online Tutoring Center</span>

       

            <div className={css.text2}>
                <span className='pt-12'>Personalized Learning</span>

                <span> CHOOSE SUCCESS</span>
            </div>
        </div>

    {/* Middle Side Hero Image Section */}

        <div className={css.wrapper}>
            {/* Child Number 1 */}
            <motion.div 
            initial={{bottom:'17.8%'}}
            whileInView={{bottom: '12.5%'}}
            transition={transition}
            className={css.whiteCircle}></motion.div>
            
            {/* Child Number 2 */}
            <motion.img
            transition={transition}
            initial={{bottom: '-1%'}}
            whileInView={{bottom:'3.5%'}}
             src={HeroImg} alt='hero' width={600}/>

             {/* Child Number 3 */}
             {/* Second Circle And Image (Hero Image) */}
             <motion.div 
                initial={{top:'1rem'}}
                whileInView={{top: '-1rem'}}
                transition={transition}
                className={css.whiteCircle}></motion.div>
            
            {/* Child Number 4 */}
            <motion.img
                transition={transition}
                initial={{top: '0.25rem'}}
                whileInView={{top: '-1.25rem'}}
                src={HeroImg} alt='hero' width={600}/>

                {/* =============================== */}
            {/* Child Number 5 */}
            <motion.div 
            transition={transition}
            initial={{left:'55%'}}
            whileInView={{left:'66%'}}
            className={css.cart2}>
                <BiBookReader />
                
                <Link to='/contact-us'>
                <div className={css.signup}>
                    <span>FREE TRIAL SESSION</span>
                    <div className={css.arrow}>
                       <BsArrowRight/>
                    </div>
                </div>
                </Link>
            </motion.div>
             {/* Child Number 6 */}
            <motion.div 
            transition={transition}
            initial={{left:'-2.5%'}}
            whileInView={{left:'7.5%'}}
            className={css.cart2}>
                <BiBookReader />
                
                <Link to='/contact-us'>
                <div className={css.signup}>
                    <span>FREE TRIAL SESSION</span>
                    <div className={css.arrow}>
                       <BsArrowRight/>
                    </div>
                </div>
                </Link>
            </motion.div>

            {/* Child Number 7 */}
            <motion.div 
            transition={transition}
            initial={{left:'-15.5%'}}
            whileInView={{left:'-5.5%'}}
            className={css.cart2}>
                <BiBookReader />
                
                <Link to='/contact-us'>
                <div className={css.signup}>
                    <span>FREE TRIAL SESSION</span>
                    <div className={css.arrow}>
                       <BsArrowRight/>
                    </div>
                </div>
                </Link>
            </motion.div>
                {/* Child Number 8 */}
            <Whatsapp/>
                {/* Child Number 9  */}
            <WhatsappSmallScreens/>
                {/* Child Number 10  */}
            <WhatsappFourOneFour />
            {/* Child Number 11 */}
            <WhatsappThreeNineThree />
             {/* Child Number 12 */}
             <WhatsappThreeSevenFive />
             {/* Child Number 13 */}
             <WhatsappThreeSixZero />
             {/* Child Number 14 */}
             <WhatsappMain />

             {/* Child Number 15 */}
             <WhatsappThirteen />
             {/* Child Number 16 */}
             <WhatsappAlf/>
        </div>

    {/* Right Side */}
        <div className={css.h_sides}>
        
            <div className={css.traffic}>
                <span>200+</span>
                <span>SUCCESS STORIES</span>
            </div>

            <div className={css.customers}>
                <span>140+</span>
                <span>TRUSTED TUTORS</span>
            </div>
           
        </div>

    </div>
  )
}

export default Hero;




















