import React, { useRef, useState } from 'react';
import 'react-phone-number-input/style.css'
import IconInfo from '../../components/iconInfo/IconInfo';
import IconEmail from '../../components/icons/IconEmail';
import IconPhone from '../../components/icons/IconPhone';
import { Controller, useForm } from "react-hook-form";
import FormElement from '../../components/formElement/FormElement';
import PhoneInputWithCountry from 'react-phone-number-input';
import { useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import { WhatsappIcon } from 'react-share';


const OtherCourses = () => {

    const navigate = useNavigate();
    const form = useRef();
    const [mobile, setMobile] = useState('');
    const [lastName, setLastName] = useState('');
    const [err, setErr] = useState('');


    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm(process.env.REACT_APP_KEY_SERVICE, process.env.REACT_APP_TEMPLATE_KEY, form.current, process.env.REACT_APP_USER_ID)
        .then((result) => {
            form.current.reset();
            setMobile('')
            console.log(result.text);
            console.log('message sent successfully');
            setErr('message sent successfully')
        }, (error) => {
            console.log(error.text);
        });
    };

    const {control, handleSubmit, formState:{errors}} = useForm({
        defaultValues: {
            first_name: '',
            lastName: '',
            mobile: '',
            email: '',
            message: ''
        }
    });

  return (



    <div className='xl:container mb-32 mx-auto'>
        <div className='flex justify-center bg-blue-600 h-[15rem]'>
               
                <h1 className='text-5xl sm:text-4xl xxs:text-[1.8rem] xs:text-[2rem] mmmIphone:text-[2rem] IphoneProMax:text-3xl text-white uppercase pt-12'>GET A FREE SESSION</h1>
        </div>

        <div className='px-4 sm:w-2/3 lg:w-1/2 mx-auto'>
            <div className='rounded-lg shadow-lg bg-white -mt-24 py-10 md:py-12 px-4 md:px-6'>
            
                <div className='grid grid-cols-3 gap-x-4 mb-10 mx-auto pr-2 pl-2'>
                    <IconInfo icon={<IconEmail />} text="contact@aimteach.academy"/> 
                    <IconInfo icon={<WhatsappIcon style={{width:'40px', marginLeft:'2rem', borderRadius:'30%'}} />} text="0503406781"/> 
                    <IconInfo icon={<IconPhone />} text="0585305523"/> 

                </div>
                <div>
                <div className='flex absolute iphoneTwelve:top-[13.5rem] md:top-[14.5rem] md:left-[40%] iphoneFifteen:top-[12.3rem] xxxs:top-[12.3rem] laptopmd:top-[14.5rem] laptopmd:left-[42%] mmmIphone:top-[12.6rem] mmmIphone:left-[27%] iphone:left-[33%] lg:top-3 lg:left-[65%]  top-[15rem] left-[23%] text-md font-medium text-green-300'>{err}</div>
                    <form ref={form} onSubmit={sendEmail}>
                        <Controller
                            name='first_name'
                            control={control}
                            rules={{required: true}}
                            render={(field) => (
                                <FormElement 
                                    type='text'
                                    name='first_name'
                                    label='First Name'
                                    placeholder='Please Enter Your First Name.' 
                                    fieldRef={field}
                                    hasError={errors.first_name?.type === 'required'} />
                            )}
                        />
                         <Controller
                            name='lastName'
                            control={control}
                            rules={{required: true}}
                            render={(field) => (
                                <FormElement 
                                    type='text'
                                    name='lastName'
                                    label='Last Name'
                                    placeholder='Please Enter Your Last Name.' 
                                    fieldRef={field}
                                    hasError={errors.last_name?.type === 'required'} />
                            )}
                        />
                        <label className="block text-gray-700 text-sm font-bold mb-2">Phone Number</label>
                        <Controller
                            name='mobile'
                            control={control}
                            rules={{required: true}}
                            render={(field) => (
                                
                                <PhoneInputWithCountry
                                        defaultCountry="AE"
                                        name="mobile" // whatever your name for react-hook-form is
                                        control={control} // from react-hook-form
                                        onChange={(mobile) => setMobile(mobile)}
                                       
                                        numberInputProps={{
                                            className: 'form-group mb-1 form-control w-full px-3 py-1.5 text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none' // my Tailwind classes
                                        }}
                                />
                            )}
                        />

                        <Controller
                            name='email'
                            control={control}
                            rules={{required: true}}
                            render={(field) => (
                                <FormElement 
                                    type='email'
                                    label='Email'
                                    name='email'
                                    placeholder='Enter email here...' 
                                    fieldRef={field}
                                    hasError={errors.email?.type === 'required'} />
                            )}
                        />

                        <Controller
                            name='message'
                            control={control}
                            rules={{required: true}}
                            render={(field) => (
                                <FormElement 
                                    type='textarea'
                                    name='message'
                                    label='How can we assist you?'
                                    placeholder='How can we assist you?' 
                                    fieldRef={field}
                                    hasError={errors.message?.type === 'required'} />
                            )}
                        />
                        <div className='grid grid-cols-2 gap-x-6'>
                            <button onClick={() => navigate(-1)} className='px-3 py-2 bg-transparent hover:bg-blue-500 hover:text-white border-gray-600 border border-solid rounded-md' type='button'>Previous</button>
                            <button className='px-3 py-2 bg-blue-500 text-white hover:bg-white hover:text-colorSpecialBlue border-gray-600 border border-solid rounded-md' type='submit'>Submit</button>
                       

                        </div>
                    </form>
                   
                </div>
            
            </div>
        </div>
    </div>
  )
}

export default OtherCourses;