import React from 'react';
import ButtomBannerImg from '../../assets/bottom_banner.jpeg';


const ButtomBannerTwo = () => {
  return (
    <div className='flex relative text-center justify-center h-[20rem] lg:h-[20rem] xxs:h-[10rem] xs:h-[12rem] iphone:h-[14rem]'>
            
            <img src={ButtomBannerImg} alt='bottom-banner' />
        
        <div className='flex flex-col absolute md:bottom-[40%] bottom-[50%] right-[40%] xxs:right-[30%] laptopmd:right-[40%] IphoneProMax:right-[35%]'>
            <span className='font-medium laptopmd:text-[2rem] lg:text-[1.65rem] xs:text-[.9rem] mmmIphone:text-[1rem] md:text-[1.5rem] iphone:text-[1.05rem] text-blue-800'>Ready to start your learning journey?</span>
           
        </div>
        
    </div>
  )
}

export default ButtomBannerTwo;