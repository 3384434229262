import React from 'react';

const Description = (props) => {
  
  const {title, description} = props;  
  
  return (
    <div className='flex flex-col relative justify-center items-center text-white bg-[#051f7b] iphoneFifteen:h-[22rem] sm:h-[20rem] xxxs:h-[20rem] mmmIphone:h-[18rem]  xs:h-[18rem] IphoneProMax:h-[20rem] h-[22rem] w-full'>
        <span className='absolute top-10 text-[1.65rem] xxxs:pt-2 xxs:pt-1 xxxs:text-[.95rem] iphoneFifteen:text-[1rem] xxxs:pr-[0.6rem] xxxs:pl-[1.1rem]  xs:pl-[1.55rem] xxs:pr-[0.5rem] lg:text-[1.45rem] IphoneProMax:pr-8 IphoneProMax:pl-10 pl-20 pr-14'>{title}</span>
        <div className='pt-7 lg:pt-[3.5rem] IphoneProMax:pt-[6rem] mmmIphone:pt-[5.5rem] xxxs:pt-[8rem] xxs:pt-[5rem]  sm:pb-14 pb-10'>
          <hr className='bg-red-600 w-[10rem] xxs:mt-12 h-[2px]'/>
        </div>
        <span className=' text-[1.1rem] iphoneFifteen:text-[0.9rem]  xxxs:text-[.88rem] xxxs:pt-2 xxxs:pr-[0.7rem] xxxs:pl-[1.25rem] lg:text-[1rem] xxs:pl-5 xxs:pr-5 IphoneProMax:pl-10 IphoneProMax:pr-7 pl-20 pr-14'>{description}</span>
    </div>
  )
}

export default Description;