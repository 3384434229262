import React from 'react';
import css from './Virtual.module.css';
import Shade from '../../assets/shade.png';
import ReactCompareImage from 'react-compare-image';
import Before from '../../assets/beforeImage.jpeg';
import After from '../../assets/afterImage.webp';

const Virtual = () => {
  return (
    <div className={css.Virtual}>
        <div className={css.left}>
            <span className=''>Guranteed Satisfaction</span>
            <span>We're a Tutoring Service That Keeps the Student First. <br/><br/>We're Here to Help</span>
            <span>Hover over the image </span>

            <img src={Shade} alt='shade' />
        </div>

        <div className={css.right}>
            <div className={css.wrapper}>
                <ReactCompareImage leftImage={After} rightImage={Before} />
            </div>
        </div>
    </div>
  )
}

export default Virtual;